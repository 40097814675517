$input-text-height: 50px;

.input-text {
  display: flex;
  flex-direction: column;
}

.input-text--error {
  input {
    border: 1px solid red;
  }
}

.input-text__label {
  margin-bottom: $separator-m;
}

.input-text__input-container {
  position: relative;
  width: 100%;
}

.input-text__input {
  border: 1px solid $color-black;
  background: $color-white;
  border-radius: 15px;
  height: $input-text-height;
  padding-left: $separator-m;
  padding-right: $separator-m;
  width: 100%;
  box-sizing: border-box;
}

.input-text__icon {
  display: inline-flex;

  position: absolute;
  right: $separator-m;
  transform: translateY(-50%);
  top: 50%;
}

.input-text--icon-click {
  @extend .hover-basic;
}

.input-text__error {
  margin-top: $separator-m;
}
