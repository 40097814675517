$dialog-actions-margin: $separator-m;
$dialog-separator-margin-hr: $separator-m;
$dialog-separator-margin-hr-with-custom-header: $separator-m;
$dialog-separator-custom-header: $separator-m;
$dialog-padding: $separator-m;
$dialog-text-color: grey;
$dialog-hr-height: 1px;
$dialog-footer-background: red;
$dialog-padding-left-with-icon: 46px;

.MuiDialog-paper {
  max-width: 1086px !important;
  width: 90vw;

  .input-text__label,
  .dialog-contact__checkbox-label {
    font-size: $font-size-45;
    font-family: $font-helve-neu;
  }

  .input-text__label {
    margin-bottom: 6px;
    color: $color-black;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #0077be;
  }
}

.MuiPaper-elevation24 {
  box-shadow: 80px 80px 25px #0000001f !important;
}

.dialog--with-icon {
  .MuiDialogContent-root {
    padding-left: $dialog-padding-left-with-icon !important;
  }
}

.MuiDialogContent-root {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.dialog-contact__content {
  margin: auto;
  padding-top: 97px;
}

.dialog-contact__content-title {
  max-width: 675px;
}

.MuiDialogActions-root {
  margin-top: $dialog-actions-margin;
}

.dialog__close-container {
  width: 30%;
}

.dialog__close {
  @extend .hover-basic;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dialog-contact__want {
  display: block;
  font-size: $font-size-14;
  font-family: $font-gotham-light;
  line-height: 1.2;
}

.dialog-contact__more {
  display: block;
  line-height: 0.8;

  font-size: $font-size-38;
  font-family: $font-gotham-bold;
}

.dialog-contact__text {
  display: block;
  line-height: 1;

  font-size: $font-size-14;
  font-family: $font-bernier-regular;
}

.dialog-contact__accept {
  margin-top: 80px;
}

.dialog-contact__button {
  display: flex;
  justify-content: flex-end;
}

.dialog-contact__form {
  margin-top: 50px;
  margin-bottom: 100px;
  max-width: 750px;

  .input-text {
    margin-top: 30px;
  }

  .button {
    height: 55px;
    margin-top: 30px;
    float: right;
  }

  .button__text {
    font-size: 24px;
    min-width: 200px;
  }
}

.dialog-contact__error {
  margin-top: 4px;
  display: block;
  color: red;
  font-family: $font-gotham-light;
}

.dialog-contact__checkbox {
  display: flex;
  align-items: center;
}

@media #{$breakpoints-medium} {
  .dialog-contact__want {
    font-size: $font-size-44;
  }

  .dialog-contact__more {
    font-size: $font-size-15;
  }

  .dialog-contact__text {
    font-size: $font-size-38;
  }
}
