$book-text-color: $color-black;

.book-point__title-image {
  width: 60px;
}
.book-point__title {
  display: flex;
  align-items: center;
}

.book-point__title-text-container {
  margin-left: 4px;
}

.book-point__images {
  position: relative;
  margin-top: 16px;
}

.book-point__images-background {
  width: 331px;
}

.book-point__images-book-container {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
}

.book-point__text1 {
  color: $book-text-color;
  font-family: $font-bernier-regular;
  font-size: $font-size-04;
  display: block;
  letter-spacing: 0;
  line-height: 0.9;
}

.book-point__text2 {
  color: $book-text-color;
  font-family: $font-gotham-black;
  font-size: $font-size-08;
  display: block;
  letter-spacing: 0;
  line-height: 1.2;
}

.book-point__text3 {
  color: $book-text-color;
  font-family: $font-bernier-regular;
  font-size: $font-size-04;
  display: block;
  letter-spacing: 0;
  line-height: 0.9;
}

.book-point__text4 {
  color: $book-text-color;
  font-family: $font-gotham-book;
  font-size: $font-size-09;
  line-height: 1.4;
  margin-top: 30px;
}

.book-point__text4 {
  display: block;
}

.book-point__text4-desktop {
  display: none;
}

.book-point__content {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.book-point__images-background {
  width: auto;
  max-width: 100%;
  object-fit: contain;
  height: 100%;
}

.book-point__images-book {
  width: 100%;
}

.book-point--step-page1 {
  .book-point__images-book {
    width: 82%;
    margin-top: 7%;
    margin-left: 8%;
  }
}

.book-point--step-page2 {
  .book-point__images-book {
    width: 90%;
    margin-left: 5%;
    margin-top: -7%;
  }
}

.book-point--step-page3 {
  .book-point__images-book {
    margin-top: 4%;
    max-width: 650px;
  }
}

.book-point--step-page4 {
  .book-point__images-book {
    margin-top: 5%;
    margin-left: 3%;
  }
}

.book-point--step-page5 {
  .book-point__images-book {
    margin-top: -4%;
  }
}

.book-point__button-state--desktop {
  display: none;
}

.book-point--statebook-1 {
  button {
    border-color: #e2241d;
    color: #e2241d;
  }
}

.book-point--statebook-2 {
  button {
    border-color: #00a0ac;
    color: #00a0ac;
  }
}

.book-point__button-state--mobile {
  text-align: center;
}

.book-point__range-section {
  display: flex;
}

.book-point__range {
  flex: 1 1 auto;
}

.book-point__range-image {
  width: 96px;
}

@media #{$breakpoints-medium} {
  .book-point__text1 {
    font-size: $font-size-38;
  }

  .book-point__text2 {
    font-size: $font-size-39;
  }

  .book-point__text3 {
    font-size: $font-size-38;
  }

  .book-point__title-image {
    width: auto;
  }

  .book-point__title-text-container {
    margin-left: 16px;
  }

  .book-point__content {
    flex-direction: row;
    margin-top: 30px;
  }

  .book-point__text4 {
    display: none;
  }

  .book-point__text4-desktop {
    display: block;
    font-family: $font-gotham-book;
    font-size: $font-size-06;
    margin-top: 120px;
    line-height: 1.4;
    padding-left: 30px;
    max-width: 422px;

    li {
      margin-bottom: 10px;
    }
  }

  .book-point__button-state--desktop {
    display: block;
    position: relative;
    text-align: center;
    top: 10px;
  }

  .book-point__button-state--mobile {
    display: none;
  }

  .book-point__range-image {
    width: auto;
  }
}
