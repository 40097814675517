$font-gotham-ultra: Gotham Ultra;
$font-gotham-light: Gotham Light;
$font-gotham-medium: Gotham Medium;
$font-gotham-blackita: Gotham BlackIta;
$font-gotham-black: Gotham Black;
$font-gotham-book: Gotham Book;
$font-gotham-bold: Gotham Bold;
$font-bernier-regular: BERNIER Regular;
$font-first-semi: Dosis Semi Bold;
$font-second: Lato Regular;
$font-second-bold: Lato Bold;
$font-bernhard-modern: Bernhard Modern;
$font-helve-neu: Helve Neu Lig;

$font-size-01: 49px;
$font-size-02: 38px;
$font-size-03: 28px;
$font-size-04: 25px;
$font-size-05: 21px;
$font-size-06: 20px;
$font-size-07: 54px;
$font-size-08: 24px;
$font-size-09: 16px;
$font-size-10: 39px;
$font-size-11: 45px;
$font-size-12: 68px;
$font-size-13: 40px;
$font-size-14: 39px;
$font-size-15: 82px;
$font-size-16: 78px;
$font-size-17: 53px;
$font-size-18: 13px;
$font-size-19: 46px;
$font-size-20: 44px;
$font-size-21: 43px;
$font-size-22: 47px;
$font-size-23: 32px;
$font-size-24: 30px;
$font-size-25: 62px;
$font-size-26: 34px;
$font-size-27: 41px;
$font-size-28: 97px;
$font-size-29: 86px;
$font-size-30: 90px;
$font-size-31: 93px;
$font-size-32: 65px;
$font-size-33: 89px;
$font-size-34: 95px;
$font-size-35: 110px;
$font-size-36: 60px;
$font-size-37: 96px;
$font-size-38: 50px;
$font-size-39: 48px;
$font-size-40: 100px;
$font-size-41: 250px;
$font-size-42: 120px;
$font-size-43: 64px;
$font-size-44: 56px;
$font-size-45: 22px;

// $font-size-02: 36px;
// $font-size-03: 32px;
// $font-size-04: 28px;
// $font-size-05: 22px;
// $font-size-06: 18px;
// $font-size-07: 15px;
// $font-size-08: 14px;
// $font-size-09: 15px;
// $font-size-10: 12px;
// $font-size-11: 10px;
