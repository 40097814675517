$together-text-color: $color-white;
$together-welcome-width: 164px;
$together-separator: 28px;
$together-key-width: 27px;
$together-key-width-desktop: 58px;
$together-cover-width: 185px;
$together-trazado-width: 54px;
$together-byme-width: 80px;
$together-mcmillan-width: 157px;
$together-grid-width-desktop: 816px;
$together-grid-width: 287px;

.together {
  background-color: #4ab356;
  background: linear-gradient(to top right, #ffdd00, #f7a600, #e84e0f, #e50046);
  position: relative;

  display: flex;
  //   align-items: center;
  //   justify-content: center;
  flex-direction: column;
  padding-bottom: 100px;
}

.together__grid {
  height: 100%;
  width: 100%;
  position: absolute;

  background-image: url("./../../../assets/grid.svg");
  background-size: contain;
}

.together__container {
  @extend .grid;

  margin: auto;
  display: flex;
  //   align-items: center;
  //   justify-content: center;
  flex-direction: column;
}

.together__and {
  font-family: $font-bernhard-modern;
  font-size: $font-size-31;
  color: $together-text-color;
  display: block;
  line-height: 1;
  text-shadow: $text-shadow-01;
  text-align: center;
}

.together__welcome-image-container {
  max-width: $together-welcome-width;
  position: absolute;
  top: -50px;
  left: -28px;
}

.together__welcome-image {
  max-width: 100%;
}

.together__title {
  margin: 100px auto 0 auto;
  //   display: flex;
  //   justify-content: center;
  //   flex-direction: column;
}

.together__title-container {
  position: relative;
}

.together__title-text-1,
.together__title-text-2 {
  font-family: $font-gotham-ultra;
  font-size: $font-size-02;
  color: $together-text-color;
  line-height: 1;
  text-shadow: $text-shadow-01;
}

.together__title-text-2 {
  font-size: $font-size-01;
}

.together__container2 {
  margin: auto;

  max-width: $together-grid-width;
  z-index: 10;
}

.together__explain {
  margin: $together-separator 0 0 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.together__explain-text-1 {
  color: $together-text-color;
  font-size: $font-size-03;
  font-family: $font-gotham-medium;
  display: block;
  text-shadow: $text-shadow-01;
}

.together__explain-text-2,
.together__explain-text-3 {
  font-family: $font-gotham-medium;
  color: $together-text-color;
  font-size: $font-size-02;
  display: block;
  line-height: 1;
  text-shadow: $text-shadow-01;
}

.together__explain-text-4 {
  color: $together-text-color;
  font-size: $font-size-04;
  font-family: $font-bernier-regular;
  line-height: 1;
  text-shadow: $text-shadow-01;
}

.together__key-image {
  width: $together-key-width;
}

.together__logos {
  margin-top: 22px;
}

.together__logos-first,
.together__byme-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.together__byme-container {
  position: relative;
  top: -14px;
}

.together__cover-image {
  box-shadow: 25px 25px 8px #00000029;
  margin-bottom: 24px;
}

.together__mcmillan-image {
  width: $together-mcmillan-width;
  position: relative;
  top: 7px;
}

.together__covers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;

  img {
    width: $together-cover-width;
  }
}

.together__covers--2 {
  margin-top: 0;
}

.together__lead-text,
.together__outside-text {
  color: $together-text-color;
  font-family: $font-gotham-blackita;
  font-size: $font-size-05;
  display: block;
  text-shadow: $text-shadow-01;
  letter-spacing: 3.88px;
}

.together__trazado-container {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 10px 0;
}

.together__trazado-image {
  width: $together-trazado-width;
}

.together__byme-image {
  position: relative;
  top: 12px;
  margin-right: 20px;
}

.together {
  .together__byme-image {
    height: 64px;
    width: 64px;
    box-shadow: 25px 25px 8px #00000029;
  }

  .together__outside {
    position: relative;
    top: 16px;
  }
}

.together__text {
  margin-top: 10px;
}

.together__text-p {
  color: $together-text-color;
  font-family: $font-gotham-medium;
  font-size: $font-size-06;
  display: block;
  text-shadow: $text-shadow-01;
  letter-spacing: 0;
}

.together__join-us-1,
.together__join-us-2 {
  color: $together-text-color;
  font-family: $font-gotham-ultra;
  font-size: $font-size-07;
  display: block;
  text-shadow: $text-shadow-01;
  letter-spacing: 0;
  text-transform: uppercase;
}

.together__join-and {
  color: $together-text-color;
  font-family: $font-gotham-light;
  font-size: $font-size-01;
  display: block;
  text-shadow: $text-shadow-01;
  letter-spacing: 0;
  text-transform: uppercase;
  text-align: center;
}

.together__join-us-2 {
  text-align: right;
}

.together__keys {
  display: inline-flex;
}

.together__youtube {
  margin-top: 30px;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media #{$breakpoints-medium} {

  .together {
    padding-bottom: 300px;
  }

  .together__title {
    margin-top: 200px;
  }

  .together__welcome-image-container {
    max-width: 100%;
    top: -120px;
    left: -50px;
  }

  .together__container2 {
    max-width: $together-grid-width-desktop;
  }

  .together__title-text-1,
  .together__title-text-2 {
    font-size: $font-size-15;
    line-height: 0.96;
    letter-spacing: 0px;
  }

  .together__explain {
    margin: 60px 0 0 0;
  }

  .together__explain-text-1 {
    font-size: $font-size-36;
  }

  .together__explain-text-2,
  .together__explain-text-3 {
    font-size: $font-size-37;
    letter-spacing: 0.38px;
  }

  .together__explain-text-4 {
    font-size: $font-size-21;
  }

  .together__covers {
    flex-direction: row;
    max-width: 750px;
  }

  .together__key-image {
    width: $together-key-width-desktop;
    margin-left: 2px;
    margin-right: 14px;
  }

  .together__covers {
    margin-top: 120px;
  }

  .together__covers--2 {
    margin-top: 60px;
  }

  .together__cover-image {
    width: 45% !important;
    margin-bottom: 0;
  }

  .together__mcmillan-image {
    width: 320px;
    top: 0;
  }

  .together__and {
    font-size: $font-size-41;
    margin: 0 32px 0 -22px;
  }

  .together__logos-first,
  .together__byme-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .together__logos {
    display: flex;
  }

  .together__byme-image,
  .together__trazado-image {
    width: auto;
  }

  .together {
    .together__byme-image {
      height: 85px;
      width: 85px;
    }
  }

  .together__lead-text,
  .together__outside-text {
    font-size: $font-size-26;
    letter-spacing: 6.29px;
  }

  .together__text-p {
    font-size: $font-size-23;
  }

  .together__join-us {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }

  .together__join-and {
    font-size: $font-size-36;
    margin: 0 $separator-m;
  }

  .together__join-us-1,
  .together__join-us-2 {
    font-size: $font-size-36;
  }

  .together__outside,
  .together__lead {
    position: relative;
    top: -14px;
  }

  .together__byme-image {
    top: 0;
    margin-right: 0;
  }

  .together__keys {
    margin-left: 64px;
    display: inline-flex;
  }

  .together__youtube {
    margin-top: 100px;
  }
}

@media #{$breakpoints-large} {
  .together__title-text-1,
  .together__title-text-2 {
    font-size: $font-size-35;
  }

  .together__join-us-1,
  .together__join-us-2 {
    font-size: $font-size-32;
  }

  .together__join-and {
    font-size: $font-size-32;
    margin: 0 $separator-m;
  }

  .together__key-image {
    margin-left: 12px;
  }
}
