.book {
  position: relative;
  z-index: 2;
}

.book__background {
  right: -172px;
  position: absolute;
  top: -329px;
}

.book__background-desktop {
  display: none;
}

.book__points-container {
  @extend .grid;

  position: relative;
  margin-top: 30px;
  z-index: 10;
}

.book__book-point {
  margin-bottom: 40px;
}

.book__background-bottom {
  right: -172px;
  position: absolute;
  bottom: -94px;
  z-index: 0;
}

.book__background-bottom-desktop {
  display: none;
}

@media #{$breakpoints-medium} {
  .book__background,
  .book__background-bottom {
    display: none;
  }

  .book__book-point {
    transform: translateY(120px);
    margin-bottom: 180px;
  }

  .book__background-desktop {
    z-index: 9;
    right: -40%;
    position: absolute;
    top: -354px;
    display: block;

    transform: translateX(-50%);
    left: 50%;
  }

  // .book__points-container {
  //   margin-top: 90px;
  // }

  .book__background-bottom-desktop {
    right: -40%;
    position: absolute;
    bottom: -614px;
    display: block;
    transform: translateX(-50%);
    left: 50%;
  }
}
