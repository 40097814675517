@font-face {
  font-family: "Gotham Ultra";
  src: url("../../assets/fonts/Gotham-Ultra.otf");
}

@font-face {
  font-family: "Gotham Light";
  src: url("../../assets/fonts/Gotham-Light.otf");
}

@font-face {
  font-family: "Gotham Medium";
  src: url("../../assets/fonts/Gotham-Medium.otf");
}

@font-face {
  font-family: "Gotham BlackIta";
  src: url("../../assets/fonts/Gotham-BlackIta.otf");
}

@font-face {
  font-family: "Gotham Black";
  src: url("../../assets/fonts/Gotham-Black.otf");
}

@font-face {
  font-family: "Gotham Book";
  src: url("../../assets/fonts/Gotham-Book.otf");
}

@font-face {
  font-family: "Gotham Bold";
  src: url("../../assets/fonts/Gotham-Bold.otf");
}

@font-face {
  font-family: "BERNIER Regular";
  src: url("../../assets/fonts/BERNIERT-Regular.ttf");
}

@font-face {
  font-family: "Bernhard Modern";
  src: url("../../assets/fonts/BernhardModernStd-Roman.otf");
}

@font-face {
  font-family: "Helve Neu Lig";
  src: url("../../assets/fonts/HelveticaNeue-Light.otf");
}

body {
  color: $color-black;
  font-family: $font-second;
}
