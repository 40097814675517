.transition-hover-border {
  border-width: 1px !important;
  transition-duration: 0.4s;

  &:hover {
    border-color: $color-first;
  }
}

@keyframes wobble {
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-30deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
