$button-height: 28px;
$button-height-transparent: 48px;
$button-min-width: 204px;
$button-color: $color-white;

.button {
  @extend .hover-basic;

  color: $button-color;
  height: $button-height;
  border: 0;
  background: transparent
    linear-gradient(
      180deg,
      #853c99 0%,
      #653a97 10%,
      #403894 20%,
      #1b3692 30%,
      #0b3d96 40%,
      #0255a6 50%,
      #0077be 60%,
      #0092d4 70%,
      #01a0da 80%,
      #1cb5e4 90%,
      #36caec 100%
    )
    0% 0% no-repeat padding-box;
  border: 1px solid #00000000;
  border-radius: 22px;
  padding: 0 12px;

  &:focus {
    outline: none;
  }
}

.button--color-primary {
  transform: matrix(-1, 0, 0, -1, 0, 0);

  .button__text {
    transform: matrix(-1, 0, 0, -1, 0, 0);
  }
}

.button--color-transparent {
  background: transparent;
  color: $button-color;
  box-shadow: $text-shadow-01;
  height: $button-height-transparent;
  padding: 0 30px;
  border: 3px solid #ffffff;

  .button__text {
    font-family: $font-gotham-medium;
    font-size: $font-size-06;
  }
}

.button--inline {
  display: inline-block;
}

.button__text {
  font-family: $font-gotham-book;
  font-size: $font-size-18;
}
