$grid-flexible: 90%;
$grid-max-width: 1200px !default;
$grid-max-width-fullscreen: 1200px;
$grid-max-width-content: 980px;
$gutter: 24px;

$grid-padding-left-mobile: calc((100% - #{$grid-flexible}) / 2);
$grid-padding-left-xxlarge: calc(50% - #{$grid-max-width} / 2);
$grid-padding-left-xxxlarge: calc(50% - #{$grid-max-width-fullscreen} / 2);

$margin-default-mobile: 24px;
$margin-default-xxlarge: 32px;
$margin-default-xxxlarge: 64px;
