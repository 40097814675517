$input-checkbox-color-checked: $color-black;
$input-checkbox-size: 25px;

.input-checkbox {
  display: flex;
  align-items: center;
  width: 100%;
}

.input-checkbox__input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  z-index: 9999;
}

.input-checkbox__icon-container {
  height: $input-checkbox-size;
  .icon {
    height: $input-checkbox-size;
    width: $input-checkbox-size;
  }
}

.input-checkbox__label {
  margin-left: $separator-m;
  font-size: $font-size-45;
  color: $color-black;
  font-family: $font-helve-neu;
}

.input-checkbox__icon-container--checked + .input-checkbox__label {
  color: $input-checkbox-color-checked;
}
