.range {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 16px;
}

.range__line {
  height: 22px;
  width: 100%;
  border-radius: 10px;
  align-self: center;
  background: linear-gradient(
    90deg,
    #21bfc1 0%,
    #4bcacc 10%,
    #77d6d7 20%,
    #a4e3e4 30%,
    #d0f0f0 40%,
    #fafafa 50%,
    #f9d3c6 60%,
    #f3a791 70%,
    #ee7d63 80%,
    #e9533b 90%,
    #e42c1a 100%
  );
}

.range__ball {
  height: 22px;
  width: 22px;
  border-radius: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 6px #aaa;
}
