$main-text-color: $color-white;
$main-text-color-duration: 5s;

.main {
  background-color: #4ab356;
  background: linear-gradient(to top right, #c7d41a, #76b82a, #00866a, #184a5e);
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 40px;
  padding-top: 20px;
}

.main__grid {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}

.main__image-container {
  max-height: 55%;
  max-width: 80%;
  position: relative;

  display: flex;
  align-items: center;

  margin-bottom: 20px;
}

.main__image {
  animation-duration: 2s;
  height: 100%;
  width: 100%;
  animation-delay: 0.3s;
  margin-bottom: 30px;
}

.main__logo-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  top: 0;

  transform: translateY(-6px);
  margin-bottom: 30px;
}

.main__logo {
  animation-delay: 1s;
  animation-duration: 2s;

  height: 80px;
}

.main__logo--obt {
  height: 90px;
}

.main__text-section {
  animation-duration: 3s;

  text-align: left;
  > span {
    display: block;
    line-height: 1;
  }
}

.animate__fadeOut {
  animation-duration: 3s !important;
}

.main__text-1 {
  font-size: $font-size-19;
  font-family: $font-gotham-ultra;
  color: $main-text-color;
  text-shadow: $text-shadow-01;
  text-transform: uppercase;
  letter-spacing: 0;
  opacity: 1;
}

.main__text-2,
.main__text-4 {
  font-size: $font-size-19;
  font-family: $font-bernier-regular;
  color: $main-text-color;
  text-shadow: $text-shadow-01;
  letter-spacing: 0.46px;
  opacity: 1;
}

.main__text-3 {
  font-size: $font-size-20;
  font-family: $font-gotham-light;
  color: $main-text-color;
  text-shadow: $text-shadow-01;
  letter-spacing: 0;
  opacity: 1;
}

.main__text-5 {
  font-size: $font-size-21;
  font-family: $font-gotham-light;
  color: $main-text-color;
  text-shadow: $text-shadow-01;
  letter-spacing: 0;
  opacity: 1;
}

.main__text-6,
.main__text-7 {
  font-size: $font-size-22;
  font-family: $font-gotham-ultra;
  color: $main-text-color;
  text-shadow: $text-shadow-01;
  letter-spacing: 0;
  opacity: 1;
}

.main__text-6 {
  letter-spacing: 0.9px;
}

.main__text-7 {
  letter-spacing: 1.41px;
}

.main__text-8 {
  font-size: $font-size-23;
  font-family: $font-bernier-regular;
  color: $main-text-color;
  text-shadow: $text-shadow-01;
  letter-spacing: 0;
  opacity: 1;
}

.main__text-9 {
  font-size: $font-size-24;
  font-family: $font-gotham-light;
  color: $main-text-color;
  text-shadow: $text-shadow-01;
  letter-spacing: 0;
  opacity: 1;
  letter-spacing: 0.82px;
}

.main__text-10 {
  font-size: $font-size-25;
  font-family: $font-bernier-regular;
  color: $main-text-color;
  text-shadow: $text-shadow-01;
  letter-spacing: 0;
  opacity: 1;

  margin-left: $separator-m;
  margin-top: $separator-m;

  > span {
    display: block;
    font-size: 53px;
    line-height: 0.9;
  }
}

.main__text-11 {
  font-family: $font-bernier-regular;
  font-size: $font-size-26;

  color: $main-text-color;
  text-shadow: $text-shadow-01;
  letter-spacing: 0.34px;
  opacity: 1;
}

.main__text-12 {
  font-size: $font-size-02;
  font-family: $font-gotham-light;
  color: $main-text-color;
  text-shadow: $text-shadow-01;
  letter-spacing: 0;
  opacity: 1;
  letter-spacing: 1.9px;
}

.main__text-13 {
  font-size: $font-size-27;
  font-family: $font-gotham-ultra;
  color: $main-text-color;
  text-shadow: $text-shadow-01;
  letter-spacing: 0;
  opacity: 1;
  letter-spacing: 0.82px;
}

.main__text-container {
  margin: 0 auto;

  > div {
    margin-bottom: $separator-xxxl;
  }
}

.main__text-section--2 {
  margin-bottom: 50px !important;
}

.main__ico-brain {
  margin-left: $separator-xxl;
  position: relative;
  top: 8px;
  height: 40px;
}

.main__double-wrong {
  display: flex;
}

.main__text-1-light--for {
  font-size: 30px;
}

.main__text-wrap {
  margin-left: $separator-l;
  margin-top: $separator-m;
  > span {
    display: block;
    font-size: 53px;
    line-height: 0.9;
  }
}

.main__arrow-down {
  display: none;
}

@media #{$breakpoints-medium} {
  .main {
    height: 90vh;
    min-height: 780px;
    justify-content: space-between;
  }

  .main__grid {
    background-image: url("./../../../assets/grid.svg");
    background-size: contain;
  }

  .main__logo-container {
    transform: translateY(20px);
  }

  .main__text-1 {
    font-size: $font-size-28;
    line-height: 0.7;
  }

  .main__text-3 {
    font-size: $font-size-15;
    line-height: 0.7;
  }

  .main__text-2,
  .main__text-4 {
    letter-spacing: 0.86px;
    font-size: $font-size-29;
    line-height: 0.7;
  }

  .main__text-5 {
    font-size: $font-size-30;
    line-height: 0.7;
  }

  .main__text-6 {
    font-size: $font-size-31;
    line-height: 0.7;
    letter-spacing: 1.86px;
  }

  .main__text-7 {
    font-size: $font-size-31;
    line-height: 0.7;
    letter-spacing: 2.94px;
  }

  .main__text-8 {
    font-size: $font-size-32;
    line-height: 0.7;
    letter-spacing: 0;
  }

  .main__text-9 {
    font-size: $font-size-25;
    letter-spacing: 0.62px;
  }

  .main__text-10 {
    align-items: center;
    margin-top: 0;
    > span {
      display: inline;
      margin-right: $separator-m;
      font-size: $font-size-32;
      line-height: 0.7;
      letter-spacing: 0;
    }
  }

  .main__text-11 {
    font-size: $font-size-16;
    line-height: 0.9;
    letter-spacing: 0.78px;
  }

  .main__text-12 {
    font-size: $font-size-33;
    line-height: 0.9;
    letter-spacing: 4.45px;
  }

  .main__text-13 {
    font-size: $font-size-34;
    line-height: 0.9;
    letter-spacing: 1.9px;
  }

  .main__text-section {
    position: absolute;
  }

  .main__text-section--2 {
    margin-bottom: 0 !important;
  }

  .main__text-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 330px;
    align-items: center;
    position: relative;
    width: 100%;

    > div {
      margin-bottom: 0;
    }
  }

  .main__logo {
    height: 150px;
  }

  .main__logo--obt {
  }

  // .main__text-section--1 {
  //   animation-delay: 1s;
  // }

  // .main__text-section--2 {
  //   animation-delay: 5.5s;
  //   margin-bottom: 20px;
  // }

  // .main__text-section--3 {
  //   animation-delay: 11s;
  // }

  .main__text-section--1 {
    animation-name: fadein;
    animation-duration: 7s;
    animation-fill-mode: forwards;
    -webkit-animation-name: fadein;
    -webkit-animation-duration: 7s;
    -webkit-animation-fill-mode: forwards;
    opacity: 0;
    animation-delay: 1s;
    animation-timing-function: ease-in-out;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .main__text-section--2 {
    animation-name: fadein;
    animation-duration: 7s;
    animation-fill-mode: forwards;
    -webkit-animation-name: fadein;
    -webkit-animation-duration: 7s;
    -webkit-animation-fill-mode: forwards;
    animation-delay: 7.3s;
    opacity: 0;
    animation-timing-function: ease-in-out;
  }

  .main__text-section--3 {
    animation-name: fadein2;
    animation-duration: 4s;
    animation-fill-mode: forwards;
    -webkit-animation-name: fadein2;
    -webkit-animation-duration: 4s;
    -webkit-animation-fill-mode: forwards;
    animation-delay: 13.7s;
    opacity: 0;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadein2 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .main__logo-container {
    transform: translateY(12px);
  }

  .main__text-section {
    > span {
      line-height: 1;
    }
  }

  .main__image {
    margin-bottom: 0;
  }

  .main__ico-brain {
    height: initial;
    margin-left: $separator-xxxl;
  }

  .main__arrow-down {
    display: block;
    margin: 50px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 50px;
    }
  }
}
