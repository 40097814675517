.footer {
  @extend .grid;

  justify-content: space-between;
  display: flex;
  padding: 30px 0;
  align-items: baseline;
}

.footer__byme {
  width: 64px;
}

.footer__mcmillan {
  width: 170px;
}

@media #{$breakpoints-medium} {
  .footer {
    align-items: center;
    max-width: 805px;
    padding: 60px 0;
  }

  .footer__mcmillan {
    width: 301px;
  }

  .footer__byme {
    width: 124px;
  }
}
