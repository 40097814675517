$sample-text-color: $color-white;
$sample-box-background: $color-white;
$sample-box-maxheight: 187px;

.sample {
  background-color: #4ab356;
  background: linear-gradient(to top right, #19bbe7, #0080c8, #273583, #953687);
  position: relative;

  display: flex;
  //   align-items: center;
  //   justify-content: center;
  flex-direction: column;
  padding-bottom: 60px;
}

.sample__grid {
  height: 100%;
  width: 100%;
  position: absolute;

  background-image: url("./../../../assets/grid.svg");
  background-size: contain;
}

.sample__title {
  text-align: center;
  line-height: 1;
  margin: 100px auto 42px auto;
}

.sample__boxs {
  @extend .grid;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sample__box {
  background: $sample-box-background;
  //   max-width: $sample-box-maxwidth;
  width: 45%;
  position: relative;
  padding: 20px;
  height: $sample-box-maxheight;
  box-sizing: border-box;
  box-shadow: $text-shadow-01;
  text-align: center;
}

.sample__box--1 {
  transform: matrix(1, -0.07, 0.07, 1, 0, 0);
  .sample__box-image,
  .sample__button {
    transform: rotate(4deg);
  }
}

.sample__box--2 {
  transform: matrix(1, 0.07, -0.07, 1, 0, 0);
  .sample__box-image,
  .sample__button {
    transform: rotate(-4deg);
  }
}

.sample__button-animation {
  // animation-duration: 2s; /* don't forget to set a duration! */
}

.sample__button {
  margin-top: 20px;
}

.sample__box-image {
  max-width: 90%;
  max-height: 60%;
}

.sample__title-text-1 {
  color: $sample-text-color;
  font-family: $font-gotham-ultra;
  font-size: $font-size-10;
  display: block;
  text-shadow: $text-shadow-01;
  letter-spacing: 0.16px;
}

.sample__title-text-2 {
  color: $sample-text-color;
  font-family: $font-bernier-regular;
  font-size: $font-size-11;
  display: block;
  text-shadow: $text-shadow-01;
  letter-spacing: 0;
}

.sample__footer {
  @extend .grid;

  margin-top: 50px;
  max-width: 310px;
}

.sample__footer-container {
  display: flex;
  align-items: center;
}
.sample__footer-text-1 {
  color: $sample-text-color;
  font-family: $font-bernier-regular;
  font-size: $font-size-12;
  display: block;
  text-shadow: $text-shadow-01;
  letter-spacing: 0;
  line-height: 0.9;
  flex: 0 0 auto;
}

.sample__footer-text-2 {
  font-family: $font-gotham-light;
  color: $sample-text-color;
  font-size: $font-size-13;
  display: block;
  text-shadow: $text-shadow-01;
  letter-spacing: 0;
  line-height: 0.9;
}

.sample__footer-text-3,
.sample__footer-text-3-desktop {
  font-family: $font-bernier-regular;
  color: $sample-text-color;
  font-size: $font-size-14;
  display: block;
  text-shadow: $text-shadow-01;
  letter-spacing: 0;
  line-height: 0.9;

  margin-left: $separator-m;
}

.sample__footer-container--2 {
  justify-content: space-between;
  margin-top: 10px;
}
.sample__footer-text-5 {
  font-family: $font-bernier-regular;
  color: $sample-text-color;
  font-size: $font-size-15;
  display: block;
  text-shadow: $text-shadow-01;
  letter-spacing: 0;
  line-height: 0.9;
}

.sample__footer-text-6 {
  font-family: $font-gotham-ultra;
  color: $sample-text-color;
  font-size: $font-size-16;
  display: block;
  text-shadow: $text-shadow-01;
  letter-spacing: 0.31px;

  margin-left: $separator-m;
  line-height: 0.9;
}

.sample__footer-text-7,
.sample__footer-text-8 {
  font-family: $font-gotham-ultra;
  color: $sample-text-color;
  font-size: $font-size-17;
  display: block;
  text-shadow: $text-shadow-01;
  letter-spacing: 0.21px;
  line-height: 0.9;
}

.sample__button-contact {
  z-index: 2;
  margin: 60px auto 0 auto;
}

.sample__footer-text-4-desktop,
.sample__footer-text-7-deskto,
.sample__footer-text-5-desktop {
  display: none;
}

.sample__and {
  font-family: $font-bernhard-modern;
  font-size: $font-size-43;
  color: $together-text-color;
  display: block;
  line-height: 1;
  text-shadow: $text-shadow-01;
}

.sample__footer-text-7-desktop,
.sample__footer-text-3-desktop {
  display: none;
}

@media #{$breakpoints-medium} {
  .sample__title-text-1 {
    font-size: $font-size-32;
  }

  .sample__title-text-2 {
    font-size: $font-size-16;
  }

  .sample__boxs {
    max-width: 805px;
  }

  .sample__box {
    height: 367px;
  }

  .sample__box-image {
    height: 144px;
    margin: 44px 0 44px 0;
  }

  .sample__box-image--obt {
    height: 164px;
    margin: 42px 0 26px 0;
  }

  .sample__button {
    button {
      height: 50px;
      width: 204px;
    }

    .button__text {
      font-size: 21px;
    }
  }

  .sample__footer {
    max-width: 805px;
  }

  .sample__footer-text-3 {
    display: none;
  }

  .sample__footer-text-3-desktop {
    display: block;
  }

  .sample__footer-container-desktop-1 {
    display: flex;
  }

  .sample__footer-text-2 {
    margin-left: 14px;
  }

  .sample__footer-text-1,
  .sample__footer-text-2,
  .sample__footer-text-3-desktop {
    font-size: $font-size-32;
  }

  .sample__footer-text-4-desktop {
    display: block;
    font-size: $font-size-15;

    font-family: $font-bernier-regular;
    color: $sample-text-color;
    display: block;
    text-shadow: $text-shadow-01;
    letter-spacing: 0;
    line-height: 0.9;
  }

  .together__trazado-image {
    width: 78px;
  }

  .sample__footer-text-5-desktop {
    display: block;
    font-size: $font-size-15;

    font-family: $font-bernier-regular;
    color: $sample-text-color;
    display: block;
    text-shadow: $text-shadow-01;
    letter-spacing: 0;
    line-height: 0.9;
  }

  .sample__footer-text-5,
  .sample__footer-text-7 {
    display: none;
  }

  .sample__footer-text-6 {
    margin-left: 0;
  }

  .sample__footer-container--3 {
    display: flex;
    justify-content: space-between;
  }

  .sample__footer-text-6,
  .sample__footer-text-7-desktop,
  .sample__footer-text-8 {
    font-family: $font-gotham-ultra;
    color: $sample-text-color;
    font-size: $font-size-29;
    display: block;
    text-shadow: $text-shadow-01;
    letter-spacing: 0.44px;

    line-height: 0.9;
  }

  .sample__button-contact {
    button {
      height: 56px;
      width: 241px;
    }
  }

  .sample__and {
    font-size: $font-size-29;
  }

  .sample__title {
    margin-top: 500px;
  }

  .sample {
    .together__lead-text,
    .together__outside-text {
      font-size: $font-size-24;
    }
  }
}

@media #{$breakpoints-large} {
  .sample__footer-text-6,
  .sample__footer-text-7-desktop,
  .sample__footer-text-8 {
    font-size: $font-size-35;
  }

  .sample__footer-text-4-desktop,
  .sample__footer-text-5-desktop {
    font-size: $font-size-40;
  }

  .sample {
    .together__lead-text,
    .together__outside-text {
      font-size: $font-size-26;
    }
  }

  .sample__and {
    font-size: $font-size-40;
  }

  .sample__footer-text-1,
  .sample__footer-text-2,
  .sample__footer-text-3-desktop {
    font-size: $font-size-16;
  }
}
